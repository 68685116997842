import React, {useState} from "react";

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import { onepageFeaturesData } from "../../common/data";
import classnames from "classnames";
import './style.css'

import formation1 from "../../assets/images/bms/formation1.png";
import formation2 from "../../assets/images/bms/formation2.png";
import formation3 from "../../assets/images/bms/formation3.png";

const Formation = () => {
  const [activeTab, setActiveTab] = useState(1);
  const toggle_tab = tab => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
} 
console.log("active tab ? ", activeTab)
  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Formation"
          desc="Breizh Micro Services a les formations qu'il vous faut!"
        />

        <Row>
          <Col lg="12">
          <Nav justified className="flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
            <NavItem>
              <NavLink className="rounded active" id="1-tab" data-bs-toggle="pill" role="tab" aria-controls="1" aria-selected="false" 
                onClick={() => {
                  toggle_tab(1);
                }}>
                <div className="text-center py-2">
                  <h6 className={activeTab === 1 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Les Bases de l'Informatique</h6>
                </div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="rounded" id="2-tab" data-bs-toggle="pill" role="tab" aria-controls="2" aria-selected="false"
                onClick={() => {
                  toggle_tab(2);
                }} >
                <div className="text-center py-2">
                  <h6 className={activeTab === 2 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Aller plus loin</h6>
                </div>
              </NavLink>
            </NavItem>
        
            <NavItem>
              <NavLink className="rounded" id="3-tab" data-bs-toggle="pill" role="tab" aria-controls="3" aria-selected="false"
                onClick={() => {
                  toggle_tab(3);
                }} >
              <div className="text-center py-2">
                  <h6 className={activeTab === 3 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Sur Mesure</h6>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
          </Col>
        </Row>
        
        
        

        <Row className="pt-3">
          <Col className="col-12">

          <Row>
            <Col lg="12" style={{textAlign: "center"}}>
              {activeTab === 1 && <img src={formation1} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 2 && <img src={formation2} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 3 && <img src={formation3} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
            </Col>
          </Row>
          
          <TabContent>
              {activeTab === 1 && <TabPane className="fade show active" id="1" role="tabpanel" aria-labelledby="1-tab" tabId="1">
                <div className="text-muted mb-0">
                
                        <h3>Les Bases de l'Informatique</h3>
                        <p>Ces formations ont pour but de vous apprendre à découvrir votre matériel
                            informatique. L'informatique ne sera plus un gros mot pour vous!
                        </p>
                        <p>Apprenez les différentes fonctions de votre ordinateur. De l'allumage à
                            la mise en arrêt de votre machine. Prenez en main votre souris ou votre
                            Pad.</p>
                        <p>Le bureau et l'arborescence des fichiers n'auront plus de
                            secret pour vous et vous n'aurez aucun mal à imprimer la photo que
                            vous souhaitez, ni même lire ou graver un CD/DVD.</p>
                        <p><span className="text-imp">"</span>Les Bases de l'Informatique<span className="text-imp">"</span> est une
                            formation pour les personnes qui découvre l'informatique de zéro.</p>
                        <p><em>Il n'est jamais trop tard pour commencer!</em></p>
                    
                </div>
              </TabPane>}
              {activeTab === 2 && <TabPane className="fade show active" id="2" role="tabpanel" aria-labelledby="2-tab" tabId="2">
                <div className="text-muted mb-0">
                
                    <h3>Aller plus loin</h3>
                    <p>Vous avez votre ordinateur en main et vous souhaitez aller plus loin?</p>
                    <p>Dans cette série de formations, vous allez apprendre à naviguer comme il
                        soit doit sur Internet mais aussi communiquer via Internet.</p>
                    <p>Nous allons voyager dans ce merveilleux monde qu'est l'Internet, en
                        apprendre ses raccourcis mais aussi appréhender ses dangers (Les
                        menaces, le contrôle parental, payer sur Internet).</p>
                    <p>Vous allez apprendre à utiliser une boite Mail et à la gérer.</p>
                    <p>Un pan de ces formation est aussi consacré à la Bureautique. Apprenez à
                        rédiger une lettre ou tout autre document avec les outils
                        bureautique à votre disposition. Découvrez la mise en page, les polices
                        et la mise en forme. Allez au bout de la démarche en envoyant votre
                        travail sous forme de PDF via votre boite Mail.</p>
                                        

                </div>
              </TabPane>}
              {activeTab === 3 && <TabPane className="fade show active" id="3" role="tabpanel" aria-labelledby="3-tab" tabId="3">
                <div className="text-muted mb-0">
               
               
                    <h3>Sur Mesure</h3>
                    <p>Car nous savons que chaque personne est unique et avance à son<span className="text-imp">
                        </span>rythme, nous proposons des formations sur mesure.</p>
                    <p>Que vous souhaitiez apprendre exclusivement à gérer vos photos, ou encore
                        à faire de la retouche photo, <span className="text-imp">Breizh Micro Service </span>
                        saura vous proposer la formation à votre besoin.</p>
                    <p>Nos Formations visent aussi les Professionnels, alors si vous
                        désirez proposer une formation spécifique à vos collaborateurs, appelez
                        nous afin que nous travaillons ensemble sur son contenu et sa
                        présentation pour coller au plus prés du besoin de votre entreprise.</p>
                                       
                                           
                </div>
              </TabPane>}
          </TabContent>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
};

export default Formation;
