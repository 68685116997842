import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  FormFeedback
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const ContactUs = () => {
  const [succeMsg, setsucceMsg] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Email is required"),
    }),
    onSubmit: (values) => {
      setsucceMsg(true)
      // console.log(values)
    }
  });
  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="NOUS CONTACTER"
          desc="Un besoin? Une question? Nous sommes là pour vous."
        />

        <Row className="align-items-center">
          <Col
            lg="6"
            md={{ size: 6, order: 2 }}
            xs={{ order: 1 }}
            className="mt-4 pt-2 order-1 order-md-2"
          >
            <div className="title-heading ms-lg-4">

              <Card className="border-0 bg-transparent">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Adresse</h6>
                      ZA Bande Saint Denis <br />
                      56800 Ploermel
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="border-0 bg-transparent">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="clock"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Horaires</h6>
                      Du Lundi au Vendredi: 9h-12h15 et de 13h45-19h <br />
                      Le Samedi: 10h-12h15 et de 13h45 à 18h.
                    </div>
                  </div>
                </CardBody>
              </Card>

            </div>
          </Col>

          <Col
            lg="6"
            md={{ size: 6, order: 2 }}
            xs={{ order: 1 }}
            className="mt-4 pt-2 order-1 order-md-2"
          >
            <div className="title-heading ms-lg-4">

              

              <Card className="border-0 bg-transparent">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Email</h6>
                      <Link to="#" className="text-primary">
                        contact@breizhmicro.fr
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="border-0 bg-transparent">
                <CardBody className="p-0">
                  <div
                    className="contact-detail d-flex align-items-center mt-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="content overflow-hidden d-block">
                      <h6 className="fw-bold mb-0">Phone</h6>
                      <Link to="#" className="text-primary">
                        02 97 75 88 06
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>


            </div>
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ContactUs;
