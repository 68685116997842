import React, {useState} from "react";

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import { onepageFeaturesData } from "../../common/data";
import classnames from "classnames";
import './style.css'

import assistanceAtelier from "../../assets/images/bms/assistanceAtelier.jpg";
import assistanceDomicile from "../../assets/images/bms/assistanceDomicile.jpg";
import assistancePhone from "../../assets/images/bms/assistancePhone.jpg";

const Assistance = () => {
  const [activeTab, setActiveTab] = useState(1);
  const toggle_tab = tab => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
} 
console.log("active tab ? ", activeTab)
  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Assistance"
          desc="Besoin d'assistance? Nous sommes là pour vous aider."
        />

        <Row>
          <Col lg="12">
          <Nav justified className="flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
            <NavItem>
              <NavLink className="rounded active" id="1-tab" data-bs-toggle="pill" role="tab" aria-controls="1" aria-selected="false" 
                onClick={() => {
                  toggle_tab(1);
                }}>
                <div className="text-center py-2">
                  <h6 className={activeTab === 1 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Assistance à l'Atelier</h6>
                </div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="rounded" id="2-tab" data-bs-toggle="pill" role="tab" aria-controls="2" aria-selected="false"
                onClick={() => {
                  toggle_tab(2);
                }} >
                <div className="text-center py-2">
                  <h6 className={activeTab === 2 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Assistance à Domicile/Sur Site</h6>
                </div>
              </NavLink>
            </NavItem>
        
            <NavItem>
              <NavLink className="rounded" id="3-tab" data-bs-toggle="pill" role="tab" aria-controls="3" aria-selected="false"
                onClick={() => {
                  toggle_tab(3);
                }} >
              <div className="text-center py-2">
                  <h6 className={activeTab === 3 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Assistance par Téléphone</h6>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
          </Col>
        </Row>
        
        
        

        <Row className="pt-3">
          <Col className="col-12">

          <Row>
            <Col lg="12" style={{textAlign: "center"}}>
              {activeTab === 1 && <img src={assistanceAtelier} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 2 && <img src={assistanceDomicile} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 3 && <img src={assistancePhone} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
            </Col>
          </Row>

          <TabContent>
              {activeTab === 1 && <TabPane className="fade show active" id="1" role="tabpanel" aria-labelledby="1-tab" tabId="1">
                <div className="text-muted mb-0">

             
                    <p>Notre atelier est ouvert du  <span className="text-imp">Lundi au Vendredi </span> de 
                         <span className="text-imp"> 9h00 à 12h15 et de 13h45 à 19h00 </span>, ainsi que le 
                         <span className="text-imp"> Samedi </span> de  <span className="text-imp">10h à 12h15 </span> 
                            et de <span className="text-imp">13h45 à 18h00</span>. Notre équipe est à <span className="text-imp">votre
                            écoute </span> pour vous aider à trouver la
                         <span className="text-imp"> meilleure solution </span> à vos ennuis
                         <span className="text-imp"> Informatique </span> et bien plus!</p>
                    <p>Vous pouvez nous déposer  <span className="text-imp">tout type de matériel</span>, que
                        ce soit un <span className="text-imp">ordinateur </span>, un 
                         <span className="text-imp"> téléphone </span>, une <span className="text-imp">tablette </span> ou bien
                        même un GPS.</p>
                    <p>Le  <span className="text-imp">diagnostic simple</span> est facturé  <span className="text-imp">20€
                            TTC </span> et un  <span className="text-imp">diagnostic complexe </span>
                        (demandant un démontage des composants) est facturé<span className="text-imp"> 49 €
                            TTC </span>.</p>
                    <p><span>Nous vous proposons  <span className="text-imp">différents services </span>
                            d'assistance informatique</span><span> afin de
                             <span className="text-imp"> répondre </span> au mieux à tout type de
                             <span className="text-imp"> panne </span>. Cela passe par un  <span className="text-imp">simple
                                nettoyage </span> de votre ordinateur</span><span>, ou
                            encore  <span className="text-imp">l’installation d’anti-virus </span>. Ça peut
                            également être l’ <span className="text-imp">installation </span> de pilotes et
                            périphériques de votre  <span className="text-imp">matériel
                                informatique </span> </span><span>ou  <span className="text-imp">parc
                                informatique</span></span><span>. Nous proposons également
                            le  <span className="text-imp">formatage </span> de votre système d’exploitation, 
                            l’installation des mises à jour de Windows et  <span className="text-imp">bien
                                d'autres prestations</span>.</span></p>
                                            
                    
                                            
                </div>
              </TabPane>}
              {activeTab === 2 && <TabPane className="fade show active" id="2" role="tabpanel" aria-labelledby="2-tab" tabId="2">
                <div className="text-muted mb-0">

                    <p>Les équipes de <span className="text-imp">BREIZH MICRO SERVICES</span> sont capables
                        d'intervenir <span className="text-imp">directement</span> à votre 
                        <span className="text-imp"> domicile</span> pour les 
                        <span className="text-imp"> Particuliers</span>, ou sur 
                        <span className="text-imp"> site</span> pour les <span className="text-imp">Professionnels</span> dans un rayon de 30km autour de Ploërmel.
                        Il vous suffit de nous <span className="text-imp">appeler</span> au 
                        <span className="text-imp"> 02.97.75.88.06</span> pour nous faire part de votre 
                        <span className="text-imp"> problème</span> et nous fixons un rendez-vous pour
                        notre <span className="text-imp">intervention</span>. </p>
                    
                    <p>Nous intervenons à votre <span className="text-imp">domicile</span> ou à votre
                        <span className="text-imp"> entreprise</span> en moins d'une
                        heure. <span className="text-imp">Intervention</span> terminée en moins de 24h.
                    </p>
                    <p><span>Les tarifs vous serons communiqué par téléphone avant toute
                            <span className="text-imp"> intervention</span>.</span></p>

                </div>
              </TabPane>}
              {activeTab === 3 && <TabPane className="fade show active" id="3" role="tabpanel" aria-labelledby="3-tab" tabId="3">
                <div className="text-muted mb-0">
               
                    <p>Les équipes de  <span className="text-imp">BREIZH MICRO SERVICES </span> ont la
                        possibilité de vous aider  <span className="text-imp">par téléphone </span> pour
                        tout type de  <span className="text-imp">problème informatique </span>.</p>
                    <p>Il vous suffit de nous appeler au  <span className="text-imp">02.97.75.88.06 </span> ou
                        au  <span className="text-imp">07.83.93.90.89. </span> .Nous ferons le
                         <span className="text-imp">maximum </span> pour répondre à  <span className="text-imp">votre
                            besoin </span> dans la mesure du possible.</p>
                    <p>Et si le possible devient  <span className="text-imp">compliqué </span>, il nous reste
                         <span className="text-imp"> la solution </span> de se déplacer directement à
                         <span className="text-imp"> votre domicile  </span>ou <span className="text-imp"> sur site </span>, ou
                        bien encore vous pouvez déposer votre matériel à notre
                         <span className="text-imp"> atelier </span>.</p>
                    <p>Nous sommes aussi dans la capacité de  <span className="text-imp">prendre la main à
                            distance </span> afin de vous assister sur le
                         <span className="text-imp"> dépannage </span> de votre machine.</p>
                    <p>Les tarifs vous sont communiqués par téléphone avant toute
                         <span className="text-imp"> prise en charge </span>.</p>
                                           
                </div>
              </TabPane>}
          </TabContent>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
};

export default Assistance;
