import React, { Component } from "react";
import "./style.css"
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import { onepageFeaturesData } from "../../common/data";
class Team extends Component {
  
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light team" id="savoir-faire">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Nos savoir-faire"
              desc="Breizh Micro Services c'est aussi ça"
            />

            <Row className="justify-content-center">

                <Col md="12" className="text-center mt-0 mt-md-5 pt-0 pt-md-5">

                    {/* feature box */}
                    <Row style={{background:"white",opacity:0.9}}>

                        {onepageFeaturesData.map((item, key) => (
                        <Col key={key} md={4} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                            <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                                <i className={item.icon}></i>
                            </div>

                            <div className="content mt-4">
                                <h5>{item.title}</h5>
                                <p className="text-muted mb-0">{item.description}</p>
                            </div>
                            </div>
                        </Col>
                        ))}

                    </Row>
                
                </Col>

            </Row>
            
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
