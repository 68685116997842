import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "reactstrap";

//Import Image
import img from "../../assets/images/onepage/bg.png";
import classic from "../../assets/images/team/devanture.jpg";
import label1 from "../../assets/images/bms/label1.png";
import label2 from "../../assets/images/bms/label2.jpg";
import label3 from "../../assets/images/bms/label3.jpg";
import SectionTitle from "../../components/Shared/SectionTitle";
import { onepageFeaturesData } from "../../common/data";
import classnames from "classnames";
import './style.css'


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="bg-home bg-light d-flex align-items-center"
          style={{ background: `url(${classic}) center no-repeat`, height: "auto" , minHeight: '100vh', backgroundSize: 'cover'}}
          id="home"
        >
          <Container>
            <Row className="justify-content-center">

              <Col md="12" className="text-center mt-0 mt-md-5 pt-0 pt-md-5">

 

                {/* feature box */}
                <Row style={{background:"white",opacity:0.9}}>

                    <Col key={"lbl1"} md={4} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="content mt-4">
                          <img src={label1} style={{width:'100%',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}} />
                        </div>
                      </div>
                    </Col>

                    <Col key={"lbl2"} md={4} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="content mt-4">
                          <img src={label2} style={{width:'100%',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}} />
                        </div>
                      </div>
                    </Col>

                    <Col key={"lbl3"} md={4} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="content mt-4">
                          <img src={label3} style={{width:'100%',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}} />
                        </div>
                      </div>
                    </Col>

 
                </Row>

                <Row style={{background:"white",opacity:1,marginTop:"32px"}}>
                  <Col md={3} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="image position-relative d-inline-block">
                            <i className={"uil uil-wrench h2 text-primary"}></i>
                        </div>

                        <div className="content mt-4">
                            <h5>RÉPARATION</h5>
                            <p className="text-muted mb-0">Réparation de matériel Informatique et Électronique.</p>
                        </div>
                      </div>
                  </Col>
                  <Col md={3} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="image position-relative d-inline-block">
                            <i className={"uil uil-monitor h2 text-primary"}></i>
                        </div>

                        <div className="content mt-4">
                            <h5>VENTE</h5>
                            <p className="text-muted mb-0">Vente de matériel Informatique, accessoires, consommables etc...</p>
                        </div>
                      </div>
                  </Col>
                  <Col md={3} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="image position-relative d-inline-block">
                            <i className={"uil uil-phone h2 text-primary"}></i>
                        </div>

                        <div className="content mt-4">
                            <h5>ASSISTANCE</h5>
                            <p className="text-muted mb-0">Une équipe à votre écoute pour vous dépanner au quotidien.</p>
                        </div>
                      </div>
                  </Col>
                  <Col md={3} className="col-12 /*mt-5*/" style={{border: "solid 1px #f2f4fd",padding:" 24px",margin: "0px!important"}}>
                      <div className="features feature-primary">
                        <div className="image position-relative d-inline-block">
                            <i className={"uil uil-university h2 text-primary"}></i>
                        </div>

                        <div className="content mt-4">
                            <h5>FORMATION</h5>
                            <p className="text-muted mb-0">Formations en Informatique tout niveau et sur mesure.</p>
                        </div>
                      </div>
                  </Col>
                </Row>
                
                
               {/*  <div className="title-heading margin-top-100">
                  <h1
                    className="heading mb-3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Website Load Faster, and Grow Your{" "}
                    <span className="text-primary">SEO</span>
                  </h1>
                  <p
                    className="para-desc mx-auto text-muted"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    Launch your campaign and benefit from our expertise on
                    designing and managing conversion centered bootstrap v5 html
                    page.
                  </p>
                </div>

                <div
                  className="home-dashboard onepage-hero"
                  data-aos="fade-up"
                  data-aos-duration="2200"
                >
                  <img src={classic} alt="" className="img-fluid rounded" /> 
                </div>*/}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
