import React, {useState} from "react";

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import { onepageFeaturesData } from "../../common/data";
import classnames from "classnames";
import './style.css'
import reparation1 from "../../assets/images/bms/reparation1.jpg";
import reparationInfo from "../../assets/images/bms/reparationInfo.jpg";
import reparationPhone from "../../assets/images/bms/reparationPhone.jpg";

const Reparation = () => {
  const [activeTab, setActiveTab] = useState(1);
  const toggle_tab = tab => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
} 
console.log("active tab ? ", activeTab)
  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Réparation Informatique"
          desc="Une panne informatique ? BMS est là pour vous !"
        />
        
        <Row>
          <Col lg="12">
          <Nav justified className="flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
            <NavItem>
              <NavLink className="rounded active" id="1-tab" data-bs-toggle="pill" role="tab" aria-controls="1" aria-selected="false" 
                onClick={() => {
                  toggle_tab(1);
                }}>
                <div className="text-center py-2">
                  <h6 className={activeTab === 1 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Ordinateurs</h6>
                </div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="rounded" id="2-tab" data-bs-toggle="pill" role="tab" aria-controls="2" aria-selected="false"
                onClick={() => {
                  toggle_tab(2);
                }} >
                <div className="text-center py-2">
                  <h6 className={activeTab === 2 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Téléphones et Tablettes</h6>
                </div>
              </NavLink>
            </NavItem>
        
            <NavItem>
              <NavLink className="rounded" id="3-tab" data-bs-toggle="pill" role="tab" aria-controls="3" aria-selected="false"
                onClick={() => {
                  toggle_tab(3);
                }} >
              <div className="text-center py-2">
                  <h6 className={activeTab === 3 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Les autres réparations</h6>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
          </Col>
        </Row>
        
        
        

        <Row className="pt-3">
          <Col className="col-12">

          <Row>
            <Col lg="12" style={{textAlign: "center"}}>
              {activeTab === 1 && <img src={reparationInfo} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 2 && <img src={reparationPhone} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 3 && <img src={reparation1} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
            </Col>
          </Row>
          
          <TabContent>
              {activeTab === 1 && <TabPane className="fade show active" id="1" role="tabpanel" aria-labelledby="1-tab" tabId="1">
                <div className="text-muted mb-0">
                <p>Les équipes de <span className="text-imp">Breizh Micro Services</span> sont capable d'intervenir sur tout type de <span className="text-imp">panne Informatique</span>.</p>

                <p><i>Votre ordinateur est lent?</i> N'hésitez pas à nous l'amener afin que nous <span className="text-imp">diagnostiquons</span> le meilleur moyen de lui rendre sa fouge d'antan.

                De nombreuses fenêtres intempestives s'ouvrent à l'écran et <i>vous ne savez pas comment vous en débarrasser?</i> Vous êtes sûrement victime d'une <span className="text-imp">infection Informatique</span> et nous savons comment vous <span className="text-imp">aider</span>.<br />

                Vous venez de faire tomber votre <span className="text-imp">ordinateur</span> et il plutôt que de le jeter vous souhaiteriez changer son écran cassé? Cela aussi nos équipes savent le faire.

                En effet, nous pouvons intervenir sur une multitude de <span className="text-imp">pannes Informatique</span>, que ce soit de la <span className="text-imp">récupération de données perdues</span> à <span className="text-imp">l'installation de Box Internet</span> à domicile.</p>

                Notre équipe est à <span className="text-imp">votre écoute</span> et saura trouver la solution à <span className="text-imp">votre besoin</span>.
                </div>
              </TabPane>}
              {activeTab === 2 && <TabPane className="fade show active" id="2" role="tabpanel" aria-labelledby="2-tab" tabId="2">
                <div className="text-muted mb-0">
                <p>Votre smartphone a voulu prendre son envol mais la réception ne s'est pas bien passée? Rassurez vous, nous pouvons <span className="text-imp">remplacer les écrans</span> et <span className="text-imp">vitres tactiles</span> de nos chers appareils.</p>

                <p>Dans la plupart des cas, le <span className="text-imp">remplacement</span> de l'écran ou de la vitre tactile suffit à retrouver un téléphone ou une tablette <span className="text-imp">fonctionnelle</span>.<br />

                Vous devez <span className="text-imp">recharger</span> votre smartphone <span className="text-imp">tous les jours</span> car il ne tient pas la charge? Il n'est pas la peine de le changer, les équipes de <span className="text-imp">Breizh Micro Services</span> sont aussi capables de <span className="text-imp">remplacer</span> la plupart des autres pièces qui composent un téléphone ou une tablette, dont la <span className="text-imp">batterie</span>, mais aussi les boutons poussoir, le châssis, le micro etc...<br />

                La plupart du temps le <span className="text-imp">remplacement</span> de la pièce peut être fait <span className="text-imp">dans la journée,</span> il vous suffit juste de nous appeler au <span className="text-imp">02.97.75.88.06</span> afin de nous signaler votre <span className="text-imp">panne</span>.</p>

                Les prix vous seront communiquer par téléphone avant toute intervention, <span className="text-imp">aucune surprise</span> !
                </div>
              </TabPane>}
              {activeTab === 3 && <TabPane className="fade show active" id="3" role="tabpanel" aria-labelledby="3-tab" tabId="3">
                <div className="text-muted mb-0">
                
                <p><span className="text-imp">Breizh Micro Services</span> a bien d'autres cordes à son arc et nous proposons aussi la <span className="text-imp">réparation</span> d'autres <span className="text-imp">matériels électroniques</span>, comme les <span className="text-imp">consoles de jeux</span> et bien d'autres !</p>

                <p>Votre <span className="text-imp">console de jeux</span> s'éteint toute seule? Ou bien pire, elle ne s'allume plus? Là aussi nous avons peut être la <span className="text-imp">solution</span> à votre problème.<br />

                N'hésitez pas à nous ramener le <span className="text-imp">matériel</span> que vous souhaitez <span className="text-imp">réparer</span> et nous ferons le maximum pour lui redonner <span className="text-imp">une seconde vie</span>!</p>

                Arrêtons de jeter au moindre problème, <span className="text-imp">Réparons</span> ! 
                </div>
              </TabPane>}
          </TabContent>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
};

export default Reparation;
