import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import "./style.css"

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import TeamBox from "../../components/Shared/TeamBox";

//Import Images
import image1 from "../../assets/images/team/team1.png";
import image2 from "../../assets/images/team/team1.png";
import image3 from "../../assets/images/team/team3.png";
import image4 from "../../assets/images/team/team1.png";

class Team extends Component {
  state = {
    candidates: [
      {
        id: 1,
        image: image1,
        name: "Vincent D.",
        designation: "Gérant et Technicien",
        salary: "2300",
        link: "",
        socialIds: [
          { icon: "facebook", link: "#" },
          { icon: "instagram", link: "#" },
          { icon: "twitter", link: "#" },
          { icon: "linkedin", link: "#" },
        ],
      },
      {
        id: 2,
        image: image4,
        name: "Antoine B.",
        designation: "Technicien",
        salary: "1950",
        link: "",
        socialIds: [
          { icon: "facebook", link: "#" },
          { icon: "instagram", link: "#" },
          { icon: "twitter", link: "#" },
          { icon: "linkedin", link: "#" },
        ],
      },
      {
        id: 3,
        image: image3,
        name: "Lucie B.",
        designation: "Accueil et Administration",
        salary: "2540",
        link: "",
        socialIds: [
          { icon: "facebook", link: "#" },
          { icon: "instagram", link: "#" },
          { icon: "twitter", link: "#" },
          { icon: "linkedin", link: "#" },
        ],
      },
      {
        id: 4,
        image: image2,
        name: "Nicolas G.",
        designation: "Technicien",
        salary: "2190",
        link: "",
        socialIds: [
          { icon: "facebook", link: "#" },
          { icon: "instagram", link: "#" },
          { icon: "twitter", link: "#" },
          { icon: "linkedin", link: "#" },
        ],
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light team" id="team">
          <Container>
            {/* section title */}
            <SectionTitle
              title="L'ÉQUIPE BMS"
              desc="Au sein de notre atelier de 200 mètres carrés, nous sommes heureux de vous accueillir pour tout conseil à propos de votre matériel informatique."
            />

            <Row>
              {/* teambox */}
              <TeamBox candidates={this.state.candidates} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
