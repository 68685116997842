import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class GoogleMap extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60" fluid>
          <Row>
            <Col xs="12" className="p-0">
              <div className="map">
              <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d668.3390616172863!2d-2.382788204622396!3d47.929492353287564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480faf25781b4841%3A0xebd0e11237535afa!2sBreizh%20Micro%20Services!5e0!3m2!1sen!2sfr!4v1668190264888!5m2!1sen!2sfr" 
                    style={{ border: 0 }}
                    className="rounded"
                    allowFullScreen
                    loading="lazy" 
                    title="BMS Ploermel"></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default GoogleMap;
