import React, {useState} from "react";

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Link } from "react-router-dom";
//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import { onepageFeaturesData } from "../../common/data";
import classnames from "classnames";
import './style.css'
import dataRecovery from "../../assets/images/bms/dataRecovery.png";
import otherDevice from "../../assets/images/bms/otherDevice.png";
import motherboard from "../../assets/images/bms/motherboard.png";
import Form from './Form'


const ReparationElec = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const toggle_tab = tab => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
  } 
  
  function displayModal(){
    showModal === false ? setShowModal(true) : setShowModal(false)
  }

  const onFormSubmit = () => {
    setShowModal(false); // Close the modal
    setMessageSent(true)
    setTimeout(() => {
      setMessageSent(false);
    }, 5000);
  };


  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Réparation Electronique"
          desc="Une panne electronique ? BMS est là pour vous !"
        />
        
        <Row>
          <Col lg="12">
          <Nav justified className="flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
            <NavItem>
              <NavLink className="rounded active" id="1-tab" data-bs-toggle="pill" role="tab" aria-controls="1" aria-selected="false" 
                onClick={() => {
                  toggle_tab(1);
                }}>
                <div className="text-center py-2">
                  <h6 className={activeTab === 1 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Cartes mères Apple & Windows</h6>
                </div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="rounded" id="2-tab" data-bs-toggle="pill" role="tab" aria-controls="2" aria-selected="false"
                onClick={() => {
                  toggle_tab(2);
                }} >
                <div className="text-center py-2">
                  <h6 className={activeTab === 2 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Les autres appareils</h6>
                </div>
              </NavLink>
            </NavItem>
        
            <NavItem>
              <NavLink className="rounded" id="3-tab" data-bs-toggle="pill" role="tab" aria-controls="3" aria-selected="false"
                onClick={() => {
                  toggle_tab(3);
                }} >
              <div className="text-center py-2">
                  <h6 className={activeTab === 3 ? "mb-0 menuTab active" : "mb-0 menuTab"}>Récupération de données</h6>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
          </Col>
        </Row>
        
        
        

        <Row className="pt-3">
          <Col className="col-12">

          <Row>
            <Col lg="12" style={{textAlign: "center"}}>
              {activeTab === 1 && <img src={motherboard} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 2 && <img src={otherDevice} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
              {activeTab === 3 && <img src={dataRecovery} style={{height:"150px", width:'150px',boxShadow: "0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)", borderRadius: "90px", marginBottom: "25px"}} />}
            </Col>
          </Row>
          
          <TabContent>
              {activeTab === 1 && <TabPane className="fade show active" id="1" role="tabpanel" aria-labelledby="1-tab" tabId="1">
                <div className="text-muted mb-0">
                  <p>Spécialisés dans la <span className="text-imp">réparation électronique</span>, nous avons un <span className="text-imp">service dédié</span> de réparation de <span className="text-imp">carte mère</span>.</p>
                  <p>Nous sommes en mesure de <span className="text-imp">diagnostiquer</span> quel composant est en panne sur votre pc portable et de le remplacer directement.</p>
                  <p>Nous utilisons des <span className="text-imp">process industriels</span> pour remplacer des composants aussi complexes que des <span className="text-imp">chipsets, puces graphiques, RAM ou encore processeurs</span> posés en surface des cartes mère.</p>
                  <p>Nous sommes également en mesure de diagnostiquer et de réparer tout type de panne sur vos appareils <span className="text-imp">MacBook, iMacs, Macs pro ou Macs mini.</span></p>
                  <p>Vous pourrez ainsi réaliser de grandes <span className="text-imp">économies</span> en comparaison d'un remplacement de carte mère, très souvent onéreux.</p>
                  <p>Nous nous inscrivons ainsi dans l'ère du temps. Ne jetez plus les cartes mère, <span className="text-imp">réparons-les !</span></p>
                  <p>Il ne vous reste donc qu'une chose à faire, nous envoyer une demande de prise en charge.</p>
                </div>
              </TabPane>}
              {activeTab === 2 && <TabPane className="fade show active" id="2" role="tabpanel" aria-labelledby="2-tab" tabId="2">
                <div className="text-muted mb-0">
                  <p>Nous <span className="text-imp">réparons</span> également tout type d'appareil sur demande.</p>
                  <p>Des enceintes amplifiées, des barres de son, des amplificateurs en passant par les consoles de salon ou portables, ou tout autre type d'<span className="text-imp">appareil électronique</span>, nous serons en mesure de le diagnostiquer.</p>
                  <p>Il nous est possible de changer uniquement le/les composant(s) défectueux afin d'offrir une <span className="text-imp">seconde vie</span> à votre appareil, et ce, sans avoir à remplacer la <span className="text-imp">carte mère</span> entière qui est souvent une prestation dépassant la valeur de l'appareil.</p>
                  <p>Soyez avec nous dans l'ère du temps, <span className="text-imp">donnez une seconde vie</span> à vos appareils pour un monde plus <span className="text-imp">écologique</span>.</p>
                  <p>Il ne vous reste donc qu'une chose à faire, nous envoyer une demande de prise en charge.</p>
                </div>
              </TabPane>}
              {activeTab === 3 && <TabPane className="fade show active" id="3" role="tabpanel" aria-labelledby="3-tab" tabId="3">
                <div className="text-muted mb-0">
                  <p>De par nos <span className="text-imp">compétences en électronique</span>, nous sommes également en mesure de <span className="text-imp">récupérer vos données</span> là où certains diront que c'est définitivement perdu.</p>
                  <p>Ainsi, que cela soit sur votre <span className="text-imp">téléphone</span> hors service, sur vos <span className="text-imp">clés USB</span>, vos <span className="text-imp">cartes SD</span>, mais aussi sur vos <span className="text-imp">SSD</span>, nous sommes en mesure d'en <span className="text-imp">extraire les données</span> ou de <span className="text-imp">réparer le support</span> si les composants sont encore intègres.</p>
                  <p>Vos documents précieux, vos vidéos et photos souvenirs <span className="text-imp">deviennent récupérables</span> par nos soins.</p>
                  <p>Nous mettrons tout en œuvre pour <span className="text-imp">sauver vos supports</span> en fonction de chaque cas, aussi complexe soit-il.</p>
                  <p>Il ne vous reste donc qu'une chose à faire, nous envoyer une demande de prise en charge.</p>
                </div>
              </TabPane>}
              <p style={{textAlign:'center'}}>
                    <Button className="priseEnCharge" 
                            style={{border: 'solid 1px #4153cd', color: "#4153cd", background:"transparent"}}
                            onClick={() => displayModal()}> Demander une prise en charge </Button>
                  </p>
          </TabContent>
          </Col>
        </Row>

        <div>
          <Alert color="primary" isOpen={messageSent} style={{textAlign:"center"}}>
            Votre message a bien été envoyé à nos services qui vous recontacteront sous les plus brefs délais.
          </Alert>
        </div>

      </Container>
      <Modal isOpen={showModal} className={'form'}>
        <ModalHeader>Formulaire de prise en charge <span style={{position: "absolute",right: "24px", cursor:"pointer"}} onClick={() => setShowModal(false)}> X </span></ModalHeader>
        <ModalBody>
          <Form onFormSubmit={onFormSubmit} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowModal(false)}>Fermer</Button>
        </ModalFooter>
      </Modal>

     

    </React.Fragment>
  );
};

export default ReparationElec;
