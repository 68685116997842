import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Form(props){
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3i85dee', 'template_d86ds0x', form.current, '4MlZViDQlR4PfrkwV')
      .then((result) => {
          console.log(result.text);
          props.onFormSubmit();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
        
        <label style={{width:'100%', marginBottom: "0px"}}>Nom - Prénom</label>
        <input style={{width:'100%'}} type="text" name="user_name" />
      
        <label style={{width:'100%', marginTop: "12px", marginBottom: "0px"}}>Téléphone</label>
        <input style={{width:'100%'}} type="text" name="user_phone" />

        <label style={{width:'100%', marginTop: "12px", marginBottom: "0px"}}>Email</label>
        <input style={{width:'100%'}} type="email" name="user_email" />

        <label style={{width:'100%', marginTop: "12px", marginBottom: "0px"}}>Type de matériel</label>
        <select style={{width:'100%'}} name="type_materiel">
            <option value='pc_portable'>Pc Portable</option>
            <option value='pc_bureau'>Pc Bureau</option>
            <option value='autres'>Autres</option>
        </select>

        <label style={{width:'100%', marginTop: "12px", marginBottom: "0px"}}>Référence</label>
        <input style={{width:'100%'}} type="text" name="reference" />

        <label style={{width:'100%', marginTop: "12px", marginBottom: "0px"}}>Numéro de série</label>
        <input style={{width:'100%'}} type="text" name="num_serie" />

        <label style={{width:'100%', marginTop: "12px", marginBottom: "0px"}}>Message</label>
        <textarea style={{width:'100%'}} name="message" rows="12" />

        <input style={{width:'100%'}} type="submit" value="Envoyer la demande de prise en charge" />
        
    </form>
  );
};

export default Form