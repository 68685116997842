import React, { Component } from 'react'

//import images
import illustrator1 from '../../assets/images/illustrator/undraw_crypto.svg'
import faq from '../../assets/images/illustrator/faq.svg';
import SectionTitle from "../../components/Shared/SectionTitle";
import AccordianCommon2 from "../../pages/Travel/AccordianCommon2";
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class Cta extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="team">
                    <Container>
                        {/* section title */}
                        <SectionTitle
                        title="FAQ"
                        desc="Questions Fréquemment Posées"
                        />
        

                        <Container className="mt-100 mt-60">
                            <Row className="align-items-center">
                                <Col lg={7} md={6}>
                                    <div className="accordion" id="accordionExample">
                                        <AccordianCommon2
                                            question1="Le devis est-il payant?"
                                            answer1="Un devis simple (Sans démontage) est facturé 20€ TTC et un devis complexe est facturé 49€."
                                            question2="Intervenez vous à domicile?"
                                            answer2="Nous pouvons intervenir directement à votre domicile pour vous dépanner en cas de besoin. Il suffit de nous appeler pour planifier le moment de l'intervention."
                                            question3="Réparez vous les tablettes ou les téléphones?"
                                            answer3="Oui, nous réparons tous les appareils mobiles, que la panne soit matérielle ou logiciel."
                                            question4="Vendez vous du matériel d'occasion?"
                                            answer4="Bien sûr, nous vendons tout type de matériel reconditionné: PC Portable, Ordinateur de Bureau, Tablette et Téléphone. Nous vendons aussi tous ces matériels en neuf."
                                        />
                                    </div>
                                </Col>

                                <Col lg={5} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <img src={faq} alt="" />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
